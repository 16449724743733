.energy-main .co2,
.energy-main .fuel {
  display: flex;
  align-items: flex-start;
}

.energy-main .co2 p,
.energy-main .fuel p {
  font-size: 0.75rem;
  color: black;
  font-weight: 500;
}
.energy-main .co2 p span,
.energy-main .fuel p span {
  font-size: 1rem;
  font-weight: 600;
}
.energy-main .head-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.energy-main .head-right {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: flex-end;
  height: 100px;
}

.energy-main .Days-names-wrapper > :not(:last-child) {
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.energy-main .smart-charging-progressbar-wrapper > :not(:last-child) {
  margin-bottom: 0.5rem;
}
