.general-live-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  gap: 34px;
}
.general-live-grid .current-saving {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}
.general-live-grid .electric-cost-outer {
  grid-column: 3 / 6;
  grid-row: 1 / 2;
}

.general-live-grid .solar-produCtion {
  grid-column: 6 / -1;
  grid-row: 1 / 2;
}

.general-live-grid .electric-consumption {
  grid-column: 1 / 3;
  grid-row: 2 / 5;
  align-items: unset !important;
}

.general-live-grid .heat-consumption {
  grid-column: 3 / 5;
  grid-row: 2 / 5;
}
.general-live-grid .e-mobility {
  grid-column: 5 / -1;
  grid-row: 2 / 5;
}
.general-live-grid .battery-level {
  grid-column: 1 / 3;
  grid-row: 5/7;
}
.general-live-grid .pv-production {
  grid-column: 3 /5;
  grid-row: 5/7;
}
.general-live-grid .water-consuption {
  grid-column: 5/-1;
  grid-row: 5/7;
}

.live-main .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #131523;
  font-size: 1rem;
}
.live-main .main-title {
  font-size: 16px;
  /* font-size: 1.12rem; */
}
/* .live-main .percent {
  margin-top: 0.5rem;
} */
.live-main .value {
  font-size: 1.75rem;
  margin-top: 20px;
  min-height: 30px;
}
.live-main .secondary {
  color: #7e84a3;
  font-size: 0.75rem;
}

.live-main .charging {
  background-image: url("../../Assets/station.png");
  background-repeat: no-repeat;
  background-size: 100% 40%;
  justify-content: flex-end;
}
.live-main .station-card {
  border-radius: 5px;
  border: 1px solid #099cff;
  position: relative;
  /* min-height: 300px; */
}

.live-main .station-card-gray {
  border: 1px solid #cecece !important;
}
.live-main .station-card .circle {
  width: 15px;
  height: 15px;
  border: 3px solid #099cff;
  border-radius: 50%;
  margin-right: 10px;
}

.live-main .station-card-gray .circle {
  border: 3px solid #cecece !important;
}

.live-main .station-card .lines {
  width: 8px;

  position: absolute;
  top: 36%;
  left: 8px;
}

.live-main .station-card .snake {
  width: 8px;

  position: absolute;
  top: 15%;
  left: -5px;
}
.live-main .station-card .station-card-title {
  font-size: 1rem !important;
  /* font-size: 1.3rem !important; */
  font-weight: bold;
}
.live-main .station-card .station-card-sub-title {
  font-size: 0.85rem;
  /* margin: 5px 0;

  margin: auto; */
  text-align: center;
}

.live-main .station-card h5 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000000;
  margin: 0;
}
.live-main .station-card .ant-divider {
  margin: 13px 0;
  border-color: rgba(0, 0, 0, 0.2);
  width: 90%;
  min-width: 90%;
}

.live-main .station-card span {
  font-size: 0.7rem;
  color: #171717;
  font-weight: 600;
}

.live-main .station-card-gray h4 {
  color: black;
  font-weight: 500;
}

.live-main .station-card-gray h4,
.live-main .station-card-gray span,
.live-main .station-card-gray h5 {
  opacity: 0.5;
}

.live-main .station-card svg {
  font-size: 1rem;
}

.live-main .level-section {
  text-align: center;
}

@media (max-width: 770px) {
  .live-main .live-big-card {
    height: auto;
  }
}

/* ---------------------------------new live page------------------- */

.live-main2 .grid-section {
  display: grid;
  /* grid-auto-flow: column; */
  /* grid-template: 1fr 1fr 1fr / repeat(200px, minmax(200px, 1fr)); */
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: auto auto auto;

  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  gap: 30px;
}

/* .live-main2 .grid-section .charging {
  grid-column: 2 / span 2;
  grid-row: 2 / span 2;
} */

.live-main2 .solar-thermal {
  display: flex;
  flex-direction: column;
  /* min-height: 6rem; */
  align-items: flex-start;
  height: -webkit-fill-available;
  justify-content: space-around;
}
.live-main2 .title {
  min-height: 30px;
}
@media (max-width: 1500px) {
  .general-live-grid .current-saving {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }
  .general-live-grid .electric-cost-outer {
    grid-column: 4 / -1;
    grid-row: 1 / 2;
  }

  .general-live-grid .solar-produCtion {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }

  .general-live-grid .electric-consumption {
    grid-column: 1 / 5;
    grid-row: 3 / 6;
    align-items: unset !important;
  }

  .general-live-grid .heat-consumption {
    grid-column: 5 / -1;
    grid-row: 3 / 6;
  }
  .general-live-grid .e-mobility {
    grid-column: 1 / -1;
    grid-row: 6 / 8;
  }
  .general-live-grid .battery-level {
    grid-column: 1 / 4;
    grid-row: 8/10;
  }
  .general-live-grid .pv-production {
    grid-column: 4 /-1;
    grid-row: 8/10;
  }
  .general-live-grid .water-consuption {
    grid-column: 4/-1;
    grid-row: 2 / 3;
    /* grid-row: 10/11; */
  }
}

@media (max-width: 700px) {
  .general-live-grid .current-saving {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }
  .general-live-grid .electric-cost-outer {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }

  .general-live-grid .solar-produCtion {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
  }

  .general-live-grid .water-consuption {
    grid-column: 1/-1;
    grid-row: 4 / 5;
    /* grid-row: 10/11; */
  }

  .general-live-grid .electric-consumption {
    grid-column: 1 / -1;
    grid-row: 5 / 6;
    align-items: unset !important;
  }

  .general-live-grid .heat-consumption {
    grid-column: 1 / -1;
    grid-row: 6 / 7;
  }
  .general-live-grid .e-mobility {
    grid-column: 1 / -1;
    grid-row: 7 / 9;
  }
  .general-live-grid .battery-level {
    grid-column: 1 / -1;
    grid-row: 9/10;
    min-height: 300px !important;
  }
  .general-live-grid .pv-production {
    grid-column: 1 /-1;
    grid-row: 10/11;
  }
}

/* @media (max-width: 770px) {
  .live-main2 .grid-section {
    width: 90vw;

    grid-template-columns: repeat(1, 1fr);
  }
} */
