.service-dashboard-table {
    margin-top: 20px;
    padding: 20px;
    border-radius: 20px;
    border: 2px solid black;
}

.service-dashboard-create {
    display: flex;
    justify-content: flex-end;
}

.services-dashboard-button {
    background-color: rgb(240, 181, 85);
    border-radius: 10px;
    color: white;
    padding: 10px;
    width: fit-content;
    border: none;
}

.services-dashboard-header {
    display: flex;
    padding: 10px;
    border-bottom: 2px solid black;

}

.services-dashboard-body {}

.services-dashboard-row {
    display: flex;
    border-bottom: 2px solid black;
    padding: 10px;
}

.services-dashboard-head-col {
    flex: 1;
    text-align: center;
}

.services-dashboard-col {
    flex: 1;
    padding-left: 5px;
}

.services-dashboard-action {
    flex: 1;
    display: flex;
    padding-left: 5px;
    justify-content: center;
    gap: 10px;
}

.services-dashboard-head-col-2 {
    flex: 2;
    text-align: center;
    border-right: 2px solid black;
    border-left: 2px solid black;
}

.services-dashboard-col-2 {
    flex: 2;
    padding-left: 5px;
    border-right: 2px solid black;
    border-left: 2px solid black;
}