.home-main h1 {
  margin-bottom: 0;
  /* color: black !important; */
}

.electric-cost,
.electric-consumption {
  display: flex;
  min-height: 6rem;
  height: 100%;
  align-items: flex-end;
  /* justify-content: space-between; */
}
.electric-consumption {
  justify-content: space-between;
}
.electric-cost {
  width: 100%;
}


.percentage-wrapper-right {
  width: 100%;
}
.percentage-wrapper-right :nth-child(1) .ant-progress-bg {
  background-color: var(--primary);
}
.percentage-wrapper-right :nth-child(2) .ant-progress-bg {
  background-color: var(--green);
}
.percentage-wrapper-right :nth-child(3) .ant-progress-bg {
  background-color: var(--yellow);
}
.percentage-wrapper-right :nth-child(4) .ant-progress-bg {
  background-color: var(--orange);
}
/* .electric-cost-right .ant-progress-text {
  color: var(--secondaryText) !important;
} */
.ant-progress-bg {
  border-radius: 0 10px 10px 0 !important;
}
.ant-progress-inner {
  border-radius: 0 10px 10px 0 !important;
  background-color: #e6e9f4;
}

