.tooltip-bottom {
  height: 38px;
  width: 50px;
  background-color: white;
  /* background-color: #131523; */
  /* clip-path: polygon(0 1%, 50% 100%, 100% 0); */
  clip-path: polygon(0 1%, 50% 31%, 100% 0);
  position: absolute;
  bottom: -37px;
  left: 50%;
  z-index: 3;
  margin-left: -24px;
}
.tooltip-comp {
  position: relative;
  min-height: auto;
  /* min-height: 60px; */
  min-width: auto;
  /* min-width: 160px;  */
  /* background-color: #131523; */
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}
.tooltip-comp .circle-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltip-comp .head {
  margin-right: 10px;
  min-width: 100px;
  color: black;
}

.tooltip-comp .head,
.tooltip-comp .span-value {
  /* font-size: 0.8rem; */
  font-size: 15px;
  color:black
}
.tooltip-comp .span-value {
  float: right;
  font-weight: 500;
}

.tooltip-comp .tooltip-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0;
  /* margin: 10px 0; */
  width: 100%;
}
.tooltip-comp .tooltip-item .tooltip-left {
  display: flex;
  align-items: center;
}
.tooltip-comp .hollow-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  /* background-color: #131523; */
}

.tooltip-polygon {
  height: 60px;
  width: 100%;
  background-color: #fff;
  /* border: 1px solid black; */
  /* box-shadow: rgb(174 174 174) 0px 0px 10px; */
  /* clip-path: polygon(0 1%, 50% 100%, 100% 0); */
  clip-path: polygon(0 1%, 50% 31%, 100% 0);
  position: absolute;
  bottom: -58px;
  /* left: 50%; */
  z-index: 3;
  margin-left: -20px;
}

.tooltip-position {
  height: 60px;
  width: 100%;
  background-color: #fff;
  /* border: 1px solid black; */
  /* box-shadow: rgb(174 174 174) 0px 0px 10px; */
  /* clip-path: polygon(0 1%, 50% 100%, 100% 0); */
  clip-path: polygon(0 1%, 50% 31%, 100% 0);
  position: absolute;
  /* bottom: -138px; */
  /* left: 50%; */
  z-index: 3;
  margin-left: 2px;
}
