.modal-respond .ant-modal-body {
  padding-top: 7em;
}
/* .modal-respond .section {
  display: flex;
  justify-content: space-between;
} */
.modal-respond .section h5 {
  color: var(--primaryText) !important;
}
.modal-respond .secondary {
  color: var(--secondary-color) !important;
}
.modal-respond input {
  border-radius: 8px;
  height: 38px;
  max-width: 350px;
  margin-top: 10px;
}
.modal-respond p {
  font-size: 17px;
  /* margin: 5px 0; */
}
.modal-respond button {
  display: flex;
  margin: 20px auto;
  justify-content: center;
  align-items: center;
}
.modal-respond .section .name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.modal-respond:last-of-type(input) {
  margin-bottom: 10px;
}
