.LECM-container {
    padding: 0 2rem;
}

.LECM-container .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

}

.LECM-container .head .flex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.LECM-container .head .flex h5.ant-typography {
    margin-bottom: 0.2rem;
}
.LECM-container .head .date-range {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.LECM-container .head .date-range .ant-picker {
    border-radius: 8px;
    width: 172px;
    height: 36px;
    /* border: 1px solid #000; */
}

.LECM-container .btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}

.LECM-container .btn button {
    width: 133px;
    height: 24px;
    background: #070707;
    border-radius: 87px;
    color: #fff;
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.LECM-container .modal-graph{

}

.LECM-container .option-list{
    padding: 0;
    /* padding: 40px 0 70px 20px; */
}

.LECM-container .option-list .option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.LECM-container .option-list .flex-div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.LECM-container .option-list .flex-div p{
    margin-bottom: 0;
}

.LECM-container .option-list .switches .ant-switch{
    background: #dd243b;
}
.LECM-container .option-list .switches .ant-switch-checked{
    background-color: #14fd00 !important;
}
/* .LECM-container .option-list */
