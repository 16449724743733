* {
    /* scroll-behavior: smooth; */
}

:root {
    --background: "#ffffff";
    --lightBackground: '#FFFFFF';
    --text: '#171725';
    --text2: '#131523';
    --text3: '#5A607F';
    --primary: '#37A1DB';
    --primaryText: '#025699b8';
    --secondary: '#D7DBEC';
    --secondary2: '#f5f6fa';
    --secondaryText: '#7E84A3';
    --divider: '#edf1f4';
    --green: '#46C782';
    --yellow: '#DBD621';
    --orange: '#E58448';
    --red: '#DB4D56';
    --primary-color: '#0078d9cf';
    --secondary-color: '#D34848';
    --box-shadow: '0px 2px 18px rgba(0, 0, 0, 0.41)';
}

body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    background-color: var(--background);
}

th,
thead tr {
    color: var(--primary-color) !important;
    margin: 0;
    font-size: 18px;
}

td {
    color: var(--text) !important;
}

.search-input {
    height: 38px;
    border-radius: 15px;
    margin-left: 10px;
}

.btn-outlined {
    background-color: transparent !important;
    border: 1px solid var(--primaryText) !important;
    color: var(--primaryText) !important;
    border-radius: 50px;
    height: 47px;
    width: 156px;
}

.squared-btn {
    background-color: transparent !important;
    border: 1px solid var(--primaryText) !important;
    color: var(--primaryText) !important;
    border-radius: 4px !important;
    height: auto;
    width: auto !important;
    margin: 0 5px;
}

.squared-secondary {
    border: 1px solid var(--secondary-color) !important;
    color: var(--secondary-color) !important;
}

.squared-green {
    border: 1px solid green !important;
    color: green !important;
}

.secondary-border {
    border: 1px solid var(--secondary-color) !important;
}

.c-white {
    color: var(--text);
}

.small-btn {
    height: 35px !important;
}

@media (max-width: 770px) {
    h1 {
        font-size: 25px !important;
    }
}

@media (max-width: 550px) {
    h1 {
        font-size: 20px !important;
    }
}

.ck-editor__editable {
    min-height: 400px;
}

.ck-editor__editable * {
    color: black !important;
}

/* progress bar line  */
.ant-progress-inner {
    border-radius: 0 !important;
}

/* progress bar line  */

/* badge */
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
    transform: translate(0%, -3%);
}

.ant-badge-dot {
    display: none;
}

.multi-axis-chart svg {
    background-color: transparent !important;
}

.white-card {
    box-shadow: 0px 1px 4px #13152329;
    border-radius: 6px;
    background-color: var(--background);
    padding: 20px 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.flex-around {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
}

.liquid-circle .g2-html-annotation {
    font-size: 15px !important;
    /* color: #7e84a3 !important; */
    /* font-weight: 600 !important; */
    letter-spacing: 0.4px;
    /* left: 116.5px !important;
    top: 50px !important; */
}

.liquid-circle-completed .g2-html-annotation {
    font-size: 15px !important;
    color: #fff !important;
    /* left: 114.5px !important;
    top: 60px !important; */
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    width: 100%;
}

.histogram {
    margin: 50px 0;
}

.histogram h4 {
    margin: 30px 0;
}

.digitalization-header {
    margin-top: 5rem;
}

.digiDropdown .ant-select-single .ant-select-selector {
    display: block;
}

html {
    overflow-x: hidden;
    font-size: 100% !important;
}

@media only screen and (min-width: 112.5em) {
    html {
        font-size: 100% !important;
    }
}

@media only screen and (max-width: 90em) {
    html {
        font-size: 100% !important;
    }
}

@media only screen and (max-width: 64em) {
    html {
        font-size: 90% !important;
    }
}

@media only screen and (max-width: 48em) {
    html {
        font-size: 80% !important;
    }
}

@media only screen and (max-width: 26.5625em) {
    html {
        font-size: 80% !important;
    }
}

@media only screen and (max-width: 23.4375em) {
    html {
        font-size: 70% !important;
    }
}

@media only screen and (max-width: 20em) {
    html {
        font-size: 60% !important;
    }
}

@media only screen and (max-width: 7.5em) {
    html {
        font-size: 40% !important;
    }
}

.wrapper p {
    position: absolute;
    top: 50%;
    z-index: 100;
    width: 100%;
    text-align: center;
}

/* for the pen */
html,
body {
    margin: 0;
    min-height: 100%;
    background-color: #f2f2f2;
}

/* waves */
.ocean {
    height: 80px; /* change the height of the waves here */
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
}

.wave {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%230099FF'/%3E%3C/svg%3E");
    position: absolute;
    width: 200%;
    height: 100%;
    animation: wave 10s -3s linear infinite;

    transform: translate3d(0, 0, 0);
    opacity: 0.8;
}

.wave svg {
    stroke: black;
}

.wave:nth-of-type(2) {
    bottom: 0;
    animation: wave 18s linear reverse infinite;

    opacity: 0.5;
}

.wave:nth-of-type(3) {
    bottom: 0;
    animation: wave 20s -1s linear infinite;

    opacity: 0.5;
}

@keyframes wave {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-25%);
    }
    100% {
        transform: translateX(-50%);
    }
}

.loader-large{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left:0;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-spinner{
    font-size: 60px;
}

.card-col:not(:last-child) {
    margin-bottom: 1rem;
}

.card-container {
    box-shadow: 0 1px 4px #13152329;
    border-radius: 6px;
    background-color: var(--background);
    padding: 20px 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.card-container .title {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0;
}

.card-dropdown {
    color: var(--secondaryText);
    font-size: 1rem;
}
.select-filter {
    box-shadow: 0 0 5px 1px #eee;
    padding: 0.5em;
}

.select-filter-add{
    display: flex;
    align-items: center;
    justify-content: center;
}

.editable-cell-value-wrap {
    cursor: pointer;
}

.heatmap-container {
    width: 100%;
}

.heatmap-container .heatmap-row {
    display: flex;
    padding: 5px 0;

}
.heatmap-container .heatmap-row .label{
    min-width: 60px;
    max-width: 60px;
    display: flex;
    align-items: center;
    color: #131523;
    margin-right: 10px;
    font-size: 90%;
    justify-content: flex-end;
}

.heatmap-container .heatmap-row .heatmap-cells {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.heatmap-container .heatmap-row .heatmap-cell {
    flex: 1;
    max-width: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px;
    aspect-ratio: 0.5;
    text-align: center;
    font-size: 90%;
    position: relative;
}

.heatmap-container .heatmap-row .heatmap-label {
    flex: 1;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

}

.heatmap-tooltip{
    position: absolute;
    bottom: calc(100% + 18px);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
  /*  box-shadow: rgb(174 174 174) 0 0 10px;
    transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px 20px;*/
    z-index: 100000;
    pointer-events: none;
}
/*
.heatmap-tooltip:after{
    pointer-events: none;
    content: " ";
    border-top: solid 10px #fff;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-bottom: solid 0 transparent;
    position: absolute;
    top: calc(100% - 1px);
    left: calc(50% - 5px);
}*/

.two-value-percent{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}
.two-value-percent .two-value-percent-left {
    align-self: end;
}
.two-value-percent .two-value-percent-right {
    margin-top: 2rem;
    flex: 1;
}

.two-value-percent .two-value-percent-right.circled {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.two-value-percent .two-value-percent-right .bar-chart-wrapper{
    display: flex;
    flex-direction: row;
}
.two-value-percent .percentage{
    color: var(--green);
}
.two-value-percent .two-value-percent-right .bar-chart-wrapper > *:first-child{
    flex: 1;
}
.two-value-percent .two-value-percent-right .bar-chart-wrapper > *:nth-child(2){
    flex: 3;
}

.two-value-percent .two-value-percent-right .circle-chart-wrapper{
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}
.two-value-percent .two-value-percent-right .circle-chart-wrapper span{
    text-align: center;
}

.two-value-percent .two-value-percent-right .circle-chart-wrapper .ant-progress-inner{
    width: 80px !important;
    height: 80px !important;
    font-size: 14px !important;
}

.custom-tooltip-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgb(89, 89, 89);
    background-color: #fff;
    font-family: 'Poppins', serif;
    border-radius: 4px;
    padding: 10px 20px;
    filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.3));
    position: relative;
}

.custom-tooltip-container .custom-tooltip-polygon {
    height: 60px;
    width: 100%;
    background-color: #fff;
    clip-path: polygon(0 1%, 50% 31%, 100% 0);
    position: absolute;
    bottom: -58px;
    z-index: 3;
    margin-left: -20px;
}
.custom-tooltip-container .custom-tooltip-title {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 15px;
    color: #000;
}
.custom-tooltip-container .custom-tooltip-clock {
    margin-left: 10px;
    margin-right: 3px;
}

.custom-tooltip-container .custom-tooltip-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2px 0;
}

.custom-tooltip-container .custom-tooltip-line .custom-tooltip-circle {
    width: 12px;
    height: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    margin-bottom: 0;
    margin-right: 10px;
    background-color: #000;
}
.custom-tooltip-container .custom-tooltip-line .custom-tooltip-series {
    margin-bottom: 0;
    min-width: 80px;
    margin-right: 40px;
    font-weight: 400;
    color: #000;
    font-size: 15px;
}
.custom-tooltip-container .custom-tooltip-line .custom-tooltip-value {
    margin-bottom: 0;
    font-weight: 500;
    color: #000;
    font-size: 15px;
}

.bucket-input{
    border: none;
}

.bucket-input[value=""], .bucket-input:focus, .bucket-input:active{
    border: 1px solid #d9d9d9;
}

.card-modal{
    margin-top: 20px;
}

.mt-1{
    margin-top: 1rem;
}
.mt-2{
    margin-top: 2rem;
}
.mt-3{
    margin-top: 3rem;
}

.details-category{
    margin-top: 1rem;
}

.details-category:before{
    content: '';
    width: 1rem;
    position: absolute;
    height: calc(100% + 1rem);
    border-left: solid thin rgba(0,0,0,0.3);
    border-top: solid thin rgba(0,0,0,0.3);
    right: 100%;
    top: 1rem;
}
.details-category-spacer:before{
    content: '';
    width: 100%;
    position: absolute;
    height: 1px;
    border-top: solid thin rgba(0,0,0,0.3);
    right: 0.5rem;
    top: 0.7rem;
}

@media (max-width: 770px) {

    .card-container .column-chart {
        height: 200px !important;
    }
}

@media (max-width: 550px) {

    .card-container .column-chart {
        height: 210px !important;
    }
}